import { UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { AppLogoSources } from '@amzn/sitc-frontend/constants';
import { AppSwitcherContent } from '@amzn/sitc-frontend/types';
import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { AmazonPerson } from '@amzn/studios-domain-authority-java-script-clients/types/isdg';
import React from 'react';

import { UniversalHeaderConfig } from '../types/universal-header';
import { getUserProfileProps } from './user-profile';

export const BacklotLogo = () => <img src={AppLogoSources.backlot} alt="logo" />;

export const getUniversalHeaderProps = ({
  universalHeaderConfig,
  userData,
  userProfileAppEnabled,
  themeToggleEnabled,
  onThemeChange,
  defaultAppSwitcherContent,
}: {
  universalHeaderConfig: UniversalHeaderConfig;
  userData: MaybeUndefined<AmazonPerson>;
  userProfileAppEnabled: boolean;
  themeToggleEnabled: boolean;
  onThemeChange: (theme: string) => void;
  defaultAppSwitcherContent: AppSwitcherContent[];
}): UniversalHeaderProps => {
  const { disableAppSwitcher, appSwitcherContent, disableUserProfileUtility, utilities, ...restUniversalHeaderProps } =
    universalHeaderConfig;

  let universalHeaderProps = { ...restUniversalHeaderProps };

  if (!universalHeaderProps.productIdentifier && !universalHeaderProps.logo) {
    universalHeaderProps = { ...universalHeaderProps, ...{ logo: <BacklotLogo /> } };
  }

  if (!disableAppSwitcher) {
    universalHeaderProps = { ...universalHeaderProps, ...{ appSwitcherContent: appSwitcherContent || defaultAppSwitcherContent } };
  }

  if (!disableUserProfileUtility) {
    const userProfileProps = getUserProfileProps(userData, userProfileAppEnabled);
    universalHeaderProps = { ...universalHeaderProps, ...{ utilities: utilities ? [...utilities, userProfileProps] : [userProfileProps] } };
  }

  if (themeToggleEnabled) {
    universalHeaderProps = { ...universalHeaderProps, ...{ enableThemeSwitcher: themeToggleEnabled, onThemeChange } };
  }

  return universalHeaderProps;
};
