import { HeaderLink } from '@amzn/sitc-frontend/types';

import { headerNavContent } from './navContents';

export const headerLinkA: HeaderLink = {
  primary: 'Page A',
  path: '/',
};

export const headerLinkB: HeaderLink = {
  primary: 'Page B',
  path: '',
};

export const headerContent: HeaderLink = {
  primary: 'Pages',
  navContent: headerNavContent,
};
