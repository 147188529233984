import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/commons/ErrorBoundary';
import RemoteRoot from './components/RemoteRoot';
import StandaloneRoot from './components/standalone/StandaloneRoot';

const rootComponent = process.env.STANDALONE ? StandaloneRoot : RemoteRoot;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
