import { NavContent, UtilityItem } from '@amzn/sitc-frontend/types';
import { getStagePath } from '@amzn/sitc-frontend/utils';

const oopsUrl = 'https://m.media-amazon.com/images/G/01/backlot/assets/img/oops-sm.svg';

export const defaultErrorUserProfileUtility: UtilityItem = {
  primary: 'profile',
  key: 'profile',
  iconKey: 'AccountCircleOutlined',
  toolTip: 'universal_header.utility.profile.tooltip',
  navContents: [
    {
      key: 'user-details',
      navItems: [
        {
          avatarSource: oopsUrl,
          primary: 'universal_header.utility.profile.error.primary',
          secondary: 'universal_header.utility.profile.error.secondary',
        },
      ],
    },
  ],
};

export const defaultUserProfileAppNavContent: NavContent = {
  key: 'user-profile-app',
  navItems: [
    {
      primary: 'universal_header.utility.profile.user_profile_app.your_profile.primary',
      iconKey: 'AccountCircleOutlined',
      path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/your-profile'),
    },
    {
      primary: 'universal_header.utility.profile.user_profile_app.localization.primary',
      iconKey: 'LocationOnOutlinedIcon',
      path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/localization'),
    },
    {
      primary: 'universal_header.utility.profile.user_profile_app.theme.primary',
      iconKey: 'Brightness6OutlinedIcon',
      path: getStagePath('https://[{stage}.]amazonbacklot.com/user-profile/theme'),
    },
  ],
};
