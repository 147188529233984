import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import React from 'react';

import { microAppName } from '../../configs/app';
import StudiosUniversalHeaderApp from '../StudiosUniversalHeaderApp';
import { UniversalHeaderShell } from '../UniversalHeaderShell';
import { StandaloneConfigOptions } from './StandaloneConfigOptions';

const configuration: Record<string, unknown> = {
  microAppName,
  featureOverrides: { enableUserProfileApp: true, enableThemeToggle: true },
};

const radioConfig: Record<'ownChannelNames' | 'channelNames', string[]> = {
  channelNames: ['universal-header'],
  ownChannelNames: ['universal-header'],
};

const RootComponent: React.FC<MicroAppInitializationProps> = (props) => (
  <StudiosUniversalHeaderApp {...props}>
    <UniversalHeaderShell {...props} />
    <StandaloneConfigOptions {...props} />
  </StudiosUniversalHeaderApp>
);

const StandaloneRoot = () => (
  <StandaloneConfiguration
    configuration={configuration}
    microAppName={microAppName}
    radioConfig={radioConfig}
    RootComponent={RootComponent}
    userPoolMicroAppName="amzn-studios-demo-micro-app"
  />
);

export default StandaloneRoot;
