import { AppSwitcherConfigs, BacklotApps } from '@amzn/sitc-frontend/constants';
import { NavContent } from '@amzn/sitc-frontend/types';

export const getAppSwitcherContent = (stage: string): any => ({
  navItems: [
    {
      iconKey: 'DashboardOutlined',
      primary: 'universal_header.appswitcher.studiosapps.primary',
      secondary: 'universal_header.appswitcher.studiosapps.secondary',
      path: `https://studios-universal-header.app.${stage}.frontend-platform.studios.a2z.com/`,
      newTab: false,
    },
  ],
});

export const getGroupedAppSwitcherContent = (stage: string): any => [
  {
    navItems: [
      {
        ...AppSwitcherConfigs[BacklotApps.Backlot],
        path: `https://studios-universal-header.app.${stage}.frontend-platform.studios.a2z.com/`,
      },
    ],
  },
  {
    subheader: 'BACKLOT APPS',
    navItems: [
      AppSwitcherConfigs[BacklotApps.SourceLibrary],
      AppSwitcherConfigs[BacklotApps.Release],
      AppSwitcherConfigs[BacklotApps.OriginView],
      AppSwitcherConfigs[BacklotApps.OriginAuthor],
      AppSwitcherConfigs[BacklotApps.SourcePlan],
      AppSwitcherConfigs[BacklotApps.Almanac],
      AppSwitcherConfigs[BacklotApps.Mgm],
      AppSwitcherConfigs[BacklotApps.Salesforce],
      AppSwitcherConfigs[BacklotApps.StudiosApps],
    ],
  },
  {
    subheader: 'THIRD PARTY APPS',
    navItems: [
      AppSwitcherConfigs[BacklotApps.Rightsline],
      AppSwitcherConfigs[BacklotApps.Iconik],
      AppSwitcherConfigs[BacklotApps.Airtable],
      AppSwitcherConfigs[BacklotApps.Fabric],
    ],
  },
];

export const headerNavContent: NavContent = {
  navItems: [
    {
      primary: 'Page C',
      path: '/',
    },
    {
      primary: 'Page C-A',
      path: '',
    },
    {
      primary: 'Page C-B',
      path: '',
    },
  ],
};
