import React from 'react';

import StudiosUniversalHeaderApp, { StudiosUniversalHeaderAppProps } from './StudiosUniversalHeaderApp';

const UniversalHeaderShell = React.lazy(() =>
  import('./UniversalHeaderShell').then(({ UniversalHeaderShell: shell }) => ({ default: shell }))
);

const RemoteRoot: React.FC<StudiosUniversalHeaderAppProps> = (props) => (
  <StudiosUniversalHeaderApp {...props}>
    <UniversalHeaderShell {...props} />
  </StudiosUniversalHeaderApp>
);

export default RemoteRoot;
