import { NavContent, UtilityItem } from '@amzn/sitc-frontend/types';
import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { AmazonPerson } from '@amzn/studios-domain-authority-java-script-clients/types/isdg';

import { defaultErrorUserProfileUtility, defaultUserProfileAppNavContent } from '../configs/user-profile';

const badgePhotoUrl = 'https://badgephotos.corp.amazon.com/?uid=';

export const getUserProfileProps = (userData: MaybeUndefined<AmazonPerson>, userProfileAppEnabled: boolean): UtilityItem => {
  const { loginId = '', firstName = '', lastName = '', jobProfileName = '' } = userData || {};

  if (!loginId || !firstName || !lastName || !jobProfileName) {
    return defaultErrorUserProfileUtility;
  }

  const navContents: NavContent[] = [
    {
      key: 'user-details',
      navItems: [
        {
          avatarSource: `${badgePhotoUrl}${loginId}`,
          primary: `${firstName} ${lastName}`,
          secondary: jobProfileName,
        },
      ],
    },
  ];

  if (userProfileAppEnabled) {
    navContents.push(defaultUserProfileAppNavContent);
  }

  return {
    primary: 'profile',
    key: 'profile',
    iconKey: 'AccountCircleOutlined',
    toolTip: 'universal_header.utility.profile.tooltip',
    navContents,
  };
};
